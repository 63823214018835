import {
	idProp,
	Model, model, prop,
} from 'mobx-keystone';

import { ImageModel } from '~/util/images/Image.model';

import { modelNamespace } from '~/util/modelNamespace';

const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

@model(`${modelNamespace.FAVORITES}/ListSummary`)
export class FavoritesListSummary extends Model({
	id: idProp.withSetter(),
	name: prop<string>(),
	image: prop<ImageModel | undefined>(),
	defaultList: prop<boolean>(),
	sortOrder: prop<number>(),
	itemCount: prop<number>(),
	detailedInfoAPI: prop<string>(),
	subtotal: prop<number | undefined>(),
}) {
	get hasImage() {
		return Boolean(this.image);
	}

	get firstLetterOfName() {
		return this.name.substring(0, 1);
	}

	get link() {
		return `/favorites/${!this.defaultList ? this.id : 'default'}`;
	}

	get formattedSubtotal() {
		return this.subtotal ? formatter.format(this.subtotal) : undefined;
	}
}
